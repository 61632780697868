import React from 'react';
import { createRoot } from 'react-dom/client';
import Integration from './components/Integration';

const DEFAULT_PARTNER = 'merkur';
const DEFAULT_LANGUAGE = 'de';

export function init({ partner, language }) {
  partner = partner || DEFAULT_PARTNER;
  language = language || DEFAULT_LANGUAGE;

  const container = document.createElement('div');
  document.body.appendChild(container);

  const root = createRoot(container);

  document.body.style.overflow = 'hidden';

  Array.from(document.getElementsByTagName('video')).forEach((video) => {
    video.pause();
  });

  window.addEventListener('message', (event) => {
    if (event.data.action === 'close-modal') {
      document.body.style.overflow = 'visible';

      Array.from(document.getElementsByTagName('video')).forEach((video) => {
        video.play();
      });

      root.unmount();
    }
  });

  root.render(
    <Integration partner={partner} language={language} />
  );
}
