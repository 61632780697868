import React, { useState } from 'react';
import styles from './Iframe.module.css';

export default function Iframe({ src }) {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      )}
      <iframe className={styles.iframe} src={src} onLoad={handleLoad} allow="camera; microphone"></iframe>
    </>
  );
}
