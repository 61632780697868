// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mqoje25CGAVSlu_gJS3h {
  display: block;
  height: 100%;
  width: 100%;
  border: none;
}

.WLWPk9O3sioeZExzhip0 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.J15e_J9PY7NMOUwwgeNM {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: ZIfFmFxIFA4zQubZdjV6 1s linear infinite;
}

.J15e_J9PY7NMOUwwgeNM::before {
  position: absolute;
  box-sizing: border-box;
  border: 5px solid #fff;
  border-radius: 50%;
  content: "";
  inset: 0;
  animation: QmusVroWZ49JFF6_B1ES 2s linear infinite;
}

@keyframes ZIfFmFxIFA4zQubZdjV6 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes QmusVroWZ49JFF6_B1ES {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Iframe.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kDAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,kDAAyC;AAC3C;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,oDAAoD;EACtD;;EAEA;IACE,gEAAgE;EAClE;;EAEA;IACE,yEAAyE;EAC3E;;EAEA;IACE,mEAAmE;EACrE;;EAEA;IACE,gEAAgE;EAClE;AACF","sourcesContent":[".iframe {\n  display: block;\n  height: 100%;\n  width: 100%;\n  border: none;\n}\n\n.loaderContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.loader {\n  position: relative;\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  animation: rotate 1s linear infinite;\n}\n\n.loader::before {\n  position: absolute;\n  box-sizing: border-box;\n  border: 5px solid #fff;\n  border-radius: 50%;\n  content: \"\";\n  inset: 0;\n  animation: prixClipFix 2s linear infinite;\n}\n\n@keyframes rotate {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes prixClipFix {\n  0% {\n    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);\n  }\n\n  25% {\n    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);\n  }\n\n  50% {\n    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);\n  }\n\n  75% {\n    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);\n  }\n\n  100% {\n    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": `mqoje25CGAVSlu_gJS3h`,
	"loaderContainer": `WLWPk9O3sioeZExzhip0`,
	"loader": `J15e_J9PY7NMOUwwgeNM`,
	"rotate": `ZIfFmFxIFA4zQubZdjV6`,
	"prixClipFix": `QmusVroWZ49JFF6_B1ES`
};
export default ___CSS_LOADER_EXPORT___;
