// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LjKA_X4Jn4vOGLUf4VIn {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LjKA_X4Jn4vOGLUf4VIn .Js7XpbMoHgZawwQSrMih {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .LjKA_X4Jn4vOGLUf4VIn .Js7XpbMoHgZawwQSrMih {
    height: 95%;
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,UAAU;EACZ;AACF","sourcesContent":[".modalWrapper {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modalWrapper .modal {\n  width: 100%;\n  height: 100%;\n}\n\n@media screen and (min-width: 768px) {\n  .modalWrapper .modal {\n    height: 95%;\n    width: 90%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `LjKA_X4Jn4vOGLUf4VIn`,
	"modal": `Js7XpbMoHgZawwQSrMih`
};
export default ___CSS_LOADER_EXPORT___;
