// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrtAlXx4azQHDKq1Ey1A {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  animation: k_jKmuT2hSCbxJSYCbbI 0.3s;
}

@keyframes k_jKmuT2hSCbxJSYCbbI {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Overlay.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;EACR,cAAc;EACd,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,oCAAsB;AACxB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".overlay {\n  position: fixed;\n  z-index: 10000;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  overflow: auto;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4);\n  animation: fadeIn 0.3s;\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `OrtAlXx4azQHDKq1Ey1A`,
	"fadeIn": `k_jKmuT2hSCbxJSYCbbI`
};
export default ___CSS_LOADER_EXPORT___;
