import React from 'react';

import Overlay from './Overlay';
import Modal from './Modal';
import Iframe from './Iframe';

import config from 'config';

export default function Integration({ partner, language }) {
  const src = `${config.integrationUrl}?partner=${partner}&language=${language}`;

  return (
    <Overlay>
      <Modal>
        <Iframe src={src} />
      </Modal>
    </Overlay>
  );
}
